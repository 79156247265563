import React from "react"



import Style from "../layout/Style"

const error = () => (

<main>
<header>
<h1>Error 404</h1>

</header>
<Style/>
</main>



)
export default error